import React, { useMemo, useState } from "react";
import { useSelector } from "../../../Store";
import Button from "../../../Components/button";
import { Tooltip } from "flowbite-react";
import AirdropConfirmModal from "../../../Components/Modals/airdropConfirm";
import { airdrop } from "../../../Utils/txSenders";
import TokenSelectionModal from "../../../Components/Modals/tokenSelection";
import { formatTokenAmount } from "../../../Utils/formatter";
import Decimal from "decimal.js";

const AirdropSection = () => {
  const [selectedCards, setSelectedCards] = useState([]);
  const [inputAirdropAmount, setInputAirdropAmount] = useState(0);
  const [isConfirmAirdropOpen, setIsConfirmAirdropOpen] = useState(false);
  const [isTokenSelectionModalOpen, setIsTokenSelectionModalOpen] =
    useState(false);
  const userTokens = useSelector((state) => state.session.userTokens);
  const walletAddress = useSelector((state) => state.app.walletAddress);

  const handleInputAmount = (e) => {
    setInputAirdropAmount(e.target.value);
  };

  const inSufficientBalance = useMemo(() => {
    const balance = new Decimal(selectedCards[0]?.balance ?? 0);
    const amount = new Decimal(inputAirdropAmount ? inputAirdropAmount : 0);
    return balance.lessThan(amount);
  }, [inputAirdropAmount, selectedCards]);

  return (
    <div className="bg-[#0c0c0c] bg-opacity-60 px-[20px] py-3 mt-[5px] border-l-2 border-green-500 rounded-t-lg">
      <div className="flex text-left">
        <div className="w-full sm:w-2/3 flex items-center">
          <div
            className={`border ${
              inSufficientBalance ? "border-red-500" : "border-gray-500"
            } bg-[#232325] bg-opacity-60 p-[10px] outline-none w-1/2 rounded-tl-lg sm:rounded-l-lg cursor-pointer hover:border-green-500`}
            onClick={() => walletAddress && setIsTokenSelectionModalOpen(true)}
          >
            {selectedCards.length > 0 ? (
              <div className="flex items-center">
                <img
                  className="rounded-full w-5 h-5 bg-cover bg-center bg-no-repeat"
                  src={selectedCards[0].icon_url}
                  alt="icon"
                />
                <p className="ml-2">{selectedCards[0].symbol}</p>
                <p className="ml-2" title={selectedCards[0].balance}>
                  {formatTokenAmount(+selectedCards[0].balance)}
                </p>
              </div>
            ) : (
              <div className="flex items-center justify-between">
                {"Select a Token"}{" "}
                <img
                  src="images/icon/chevron-down.svg"
                  className="text-gray-500"
                  alt="info"
                />
              </div>
            )}
          </div>
          <input
            type="number"
            placeholder="Input Amount"
            min={1}
            value={inputAirdropAmount}
            onChange={handleInputAmount}
            className={`bg-[#232325] bg-opacity-60 p-[10px] outline-transparent focus:border-none w-1/2 rounded-tr-lg sm:rounded-tr-none border-solid border-[1px] ${
              inSufficientBalance ? "border-red-500" : "border-green-500"
            }`}
          />
        </div>

        <Button
          onClick={() => walletAddress && setIsConfirmAirdropOpen(true)}
          className={"w-1/3 hidden sm:block rounded-r-lg"}
          content={
            <Tooltip
              content="Airdrop tokens to stakers"
              theme={{ target: "" }}
              className="bg-[#232325] bg-opacity-90 backdrop-blur-lg"
              arrow={false}
            >
              <p className="text-center">AIRDROP</p>
            </Tooltip>
          }
          disabled={inSufficientBalance}
        />
      </div>

      <Button
        onClick={() => walletAddress && setIsConfirmAirdropOpen(true)}
        className={"block sm:hidden rounded-b-lg"}
        content={"AIRDROP"}
        disabled={inSufficientBalance}
      />
      <AirdropConfirmModal
        isModalOpen={isConfirmAirdropOpen}
        onAccept={() => {
          airdrop(
            inputAirdropAmount,
            selectedCards[0]?.address ?? "",
            walletAddress
          );
          setIsConfirmAirdropOpen(false);
        }}
        onClose={() => setIsConfirmAirdropOpen(false)}
        amount={inputAirdropAmount}
        tokenSymbol={selectedCards[0]?.symbol ?? ""}
      />
      <TokenSelectionModal
        isModalOpen={isTokenSelectionModalOpen}
        onClose={() => setIsTokenSelectionModalOpen(false)}
        tokensArray={userTokens}
        setSelectedCards={setSelectedCards}
        selectedCards={selectedCards}
        onCardSelect={() => setIsTokenSelectionModalOpen(false)}
      />
    </div>
  );
};

export default AirdropSection;
